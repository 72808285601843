import React from 'react'

const Book = () => {
  return (
    <a
      className="book-container"
      href="https://www.assimil.com/es/e-methodes/1774-e-metodo-frances-9782700564921.html?affiliates=76603"
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className="book">
        <div className="book-front">
          <img alt="Book cover" src="/assets/images/e-metodo-frances.webp" />
        </div>
      </div>
    </a>
  )
}

export default Book
