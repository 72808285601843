import React from 'react'

import WordExample from './WordExample'
import WordHeader from './WordHeader'
import WordTraduction from './WordTraduction'
import SocialShare from './SocialShare'

import {parameters, parameters1} from '../../helpers/voiceCallback.js'

import SpeakerButton from './SpeakerButton'

import styled from 'styled-components'

import useIsMobile from '../../hooks/useIsMobile'

const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between; /* Adjust as needed for spacing */
  gap: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: auto;
  }
`

const BoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  @media screen and (max-width: 767px) {
    height: 600px;
  }
  /* width: 100%;
  height: 100%; */
  /* flex: 1; */
  /* @media screen and (max-width: 767px) {
    width: 100%;
  } */
`

const FrontDiv = styled.div`
  position: absolute;
  flex-direction: column;
  display: flex;
  border-radius: 8px;
  border: 3px solid black;
  width: 100%;
  height: 100%;
  background-color: white; /* Front div color */
  z-index: 2; /* Higher z-index to appear in front */
  transform: translateZ(50px); /* Adjust the depth of the front div */
  align-items: center;
  justify-content: center;
`

const BackDiv = styled.div`
  position: absolute;
  border-radius: 8px;
  border: 3px solid black;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #57d0ff;
`

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content; /* Set your desired height */
  width: 100%; /* Set your desired width */
  padding: 40px;
  /* border: 1px solid #000; Optional border for visualization */
  @media screen and (max-width: 767px) {
    padding: 0%;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
`

const Cell = styled.div`
  flex: 1;
  /* border-bottom: 3px solid #000;
  border-left: none;
  border-right: none; */
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    word-wrap: break-word;
    display: block;
    flex-wrap: wrap;
    line-break: auto;
    white-space: break-spaces;
  }
`

const Button = styled.h3`
  border: 3px solid black;
  border-radius: 8px;
  width: fit-content;
  padding: 15px;
  box-shadow: rgb(0, 0, 0) 3px 4px 0px;
`

const WordBoxV2 = ({node}) => {
  const isMobile = useIsMobile()

  return (
    <>
      <BoxWrapper>
        <BoxContainer>
          <FrontDiv>
            <GridContainer>
              <Row>
                <Cell
                  style={{textDecoration: isMobile && 'underline'}}
                  className="is-size-5 has-text-weight-medium"
                >
                  La palabra del dia es:
                </Cell>
                <Cell style={{backgroundColor: ''}}>
                  <SpeakerButton
                    id="buttonMot"
                    sentenceToSpeak={node.mot}
                    parameters={parameters}
                  >
                    {node.word}
                  </SpeakerButton>
                </Cell>
              </Row>
              <Row>
                <Cell
                  style={{textDecoration: isMobile && 'underline'}}
                  className="is-size-5 has-text-weight-medium"
                >
                  Traducción:
                </Cell>
                <Cell style={{backgroundColor: ''}}> {node.trad}</Cell>
              </Row>
              <Row>
                <Cell
                  style={{textDecoration: isMobile && 'underline'}}
                  className="is-size-5 has-text-weight-medium is-underlined"
                >
                  Ejemplo Francés:
                </Cell>
                <Cell style={{backgroundColor: ''}}>
                  <SpeakerButton
                    id="buttonEjemplo"
                    sentenceToSpeak={node.ejemplo}
                    parameters={parameters1}
                  >
                    {node.ejemplo}
                  </SpeakerButton>
                </Cell>
              </Row>
              <Row>
                <Cell
                  style={{textDecoration: isMobile && 'underline'}}
                  className="is-size-5 has-text-weight-medium"
                >
                  En Español:
                </Cell>
                <Cell className="is-size-5"> {node.tradEjemplo}</Cell>
              </Row>
            </GridContainer>
          </FrontDiv>
          <BackDiv></BackDiv>
        </BoxContainer>
      </BoxWrapper>
    </>
    // <div className="containerMot  ">
    //   <div className="box fadeIn" style={{border: '3px solid black'}}>
    //     <WordHeader word={node.mot} />
    //     <div className="container">
    //       <WordTraduction translation={node.trad} />
    //       <hr style={{marginTop: '0'}} />
    //       <WordExample
    //         example={node.ejemplo}
    //         exampleTranslation={node.tradEjemplo}
    //       />
    //     </div>
    //   </div>
    //   {/* <SocialShare word={node.mot} /> */}
    // </div>
  )
}

export default WordBoxV2
