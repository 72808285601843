import React from 'react'
import styled from 'styled-components'

const WrapperDiv = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    margin: auto;
    width: 1000px;
  }
`

const Wrapper = ({children}) => {
  return <WrapperDiv>{children}</WrapperDiv>
}

export default Wrapper
