// Allow loader to show up when click on voices buttons (need to refactor code)
const voiceStartCallback = () =>
  document.querySelector('#buttonMot').classList.toggle('is-loading')

const voiceEndCallback = () =>
  document.querySelector('#buttonMot').classList.toggle('is-loading')

const voiceStartCallback1 = () =>
  document.querySelector('#buttonEjemplo').classList.toggle('is-loading')

const voiceEndCallback1 = () =>
  document.querySelector('#buttonEjemplo').classList.toggle('is-loading')

export const parameters = {
  onstart: voiceStartCallback,
  onend: voiceEndCallback,
}

export const parameters1 = {
  onstart: voiceStartCallback1,
  onend: voiceEndCallback1,
}

// function to trigger the social component
export const socialTrigger = () => {
  if (
    !document.querySelector('.social').classList.contains('social-is-active')
  ) {
    document.querySelector('.social').classList.add('social-is-active')
    document.querySelector('.volet').classList.add('volet-is-active')
  }
}
