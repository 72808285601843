import React from 'react'
import styled from 'styled-components'

const SectionContainer = styled.div`
  width: 100%;
  margin: auto;
  display: ${props => props.display};
  order: ${props => props.order};

  @media (max-width: 768px) {
    /* justify-content: center;
    display: flex; */
    /* width: 100%; */
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 5rem auto;
  }

  /* @media (min-width: 768px) {
    flex: ${props => props.flex};
    max-width: 1000px;
  } */

  @media screen and (min-width: 1024px) {
    flex: ${props => props.flex};
    max-width: 1000px;  
}

`

const Section = ({children, flex, display, order}) => {
  return (
    <SectionContainer display={display} flex={flex} order={order}>
      {children}
    </SectionContainer>
  )
}

export default Section
