import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import DiscordLogo from './discordLogo'

const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between; /* Adjust as needed for spacing */
  gap: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: auto;
  }
`

const BoxContainer = styled.div`
  position: relative;
  width: 280px;
  height: 130px;
`

const FrontDiv = styled.div`
  position: absolute;
  flex-direction: column;
  display: flex;
  border-radius: 8px;
  border: 3px solid black;
  width: 100%;
  height: 100%;
  background-color: white; /* Front div color */
  z-index: 2; /* Higher z-index to appear in front */
  transform: translateZ(50px); /* Adjust the depth of the front div */
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; /* Add transition effect */
  &:hover {
    background: #e6e6e6;
  }
`

const BackDiv = styled.div`
  position: absolute;
  border-radius: 8px;
  border: 3px solid black;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  background-color: #e74c3c; /* Back div color */
  z-index: 1; /* Lower z-index to appear behind */
`

const CatchDiv = styled.div`
  font-size: 1.5rem;
  text-decoration: underline;

  /* Hide underline initially */
  /* text-decoration: none; */

  /* Add underline on hover */
`

const IconDiv = styled.div`
  font-size: 2rem;
`

const ThreeBoxes = () => {
  return (
    <BoxWrapper>
      <Link to="/vocabulario">
        <BoxContainer>
          <FrontDiv>
            <CatchDiv>Vocabulario</CatchDiv>
            ➡️
          </FrontDiv>
          <BackDiv style={{background: 'white'}}></BackDiv>
        </BoxContainer>
      </Link>
      <Link to="/verbos">
        <BoxContainer>
          <FrontDiv>
            <CatchDiv>Verbos</CatchDiv>
            ➡️
          </FrontDiv>
          <BackDiv style={{background: 'white'}}></BackDiv>
        </BoxContainer>
      </Link>
      <Link to="/blog">
        <BoxContainer>
          <FrontDiv>
            <CatchDiv>Artículos y canciones</CatchDiv>
            ➡️
          </FrontDiv>
          <BackDiv style={{background: 'lightgrey'}}></BackDiv>
        </BoxContainer>
      </Link>
      <a href="https://discord.gg/BqJxSvYepH">
        <BoxContainer>
          <FrontDiv>
            <CatchDiv>Únete</CatchDiv>
            <div class="is-size-3">
              <DiscordLogo height="25" />
            </div>
          </FrontDiv>
          <BackDiv style={{background: '#5865f2'}}></BackDiv>
        </BoxContainer>
      </a>
    </BoxWrapper>
  )
}

export default ThreeBoxes
