import React from 'react'
import {Link} from 'gatsby'
import Faq from 'react-faq-component'
// FOUC appear if layout not imported
import Layout from '../components/layout'
import SEO from '../components/seo'
import Cta from '../components/cta'
import ThreeBoxes from '../components/threeBoxes'
import ThreeBoxesLinks from '../components/threeBoxesLinks'
import Section from '../components/section'
import Wrapper from '../components/wrapper'
import WordBoxV2 from '../components/WordBoxV2'
import FaqData from '../../content/faqData'
import Footer from '../components/footer'
import Header from '../components/header'
import Modal from '../components/modal'
import ContentSection from '../components/contentSection'
import useRandomWord from '../hooks/useRandomWord'
import content from '../../content/pages/new.yaml'
import styled from 'styled-components'
import useAosInit from '../hooks/useAosInit'
import Book from '../components/book'
import FreeProductOffer from '../components/FreeProductOffer'

const New = ({data, pageContext}) => {
  const node = pageContext.node
  const mot = node
  const {isWordPage} = pageContext
  const {title, subtitle, sections, wordBoxSection, bottomSection} = content
  const {url} = useRandomWord(mot)
  useAosInit()

  const BoxWrapper = styled.div`
    display: flex;
    justify-content: space-between; /* Adjust as needed for spacing */
    gap: 80px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: auto;
    }
  `

  const BoxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 200px;
    align-items: center;
    display: flex;
  `

  const FrontDiv = styled.div`
    position: absolute;
    flex-direction: column;
    display: flex;
    border-radius: 8px;
    border: 3px solid black;
    width: 100%;
    /* height: 100%; */
    z-index: 2;
    transform: translateZ(50px);
    align-items: center;
    justify-content: center;
  `

  const BackDiv = styled.div`
    position: absolute;
    border-radius: 8px;
    border: 3px solid black;
    top: 12px;
    left: 10px;
    width: 100%;
    height: 77%;
    z-index: 1;
    background-color: white;
    @media (max-width: 767px) {
      display: none; /* Hide the video on mobile */
    }
  `

  const faqStyles = {
    bgColor: 'black',
    titleTextColor: 'white',
    rowTitleColor: 'white',
    rowContentColor: 'white',
    arrowColor: 'white',
  }
  // const heroStyles = {background: 'linear-gradient(#57d0ff, white)'}
  const heroTitleStyles = {maxWidth: '700'}
  const wordBoxActionsStyles = {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    socialLink: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1.5rem',
    },
    icon: {
      width: 50,
      height: 50,
      borderRadius: '50%',
      border: '5px solid black',
      padding: 10,
      boxShadow: 'rgb(0,0,0) 3px 4px 0px',
      cursor: 'pointer',
    },
    callToAction: {
      marginTop: 24,
    },
    callToAction1: {
      paddingTop: '15px',
      top: '55px',
    },
  }
  const sectionsContainerStyles = {
    backgroundImage: 'linear-gradient(to top, rgb(87, 208, 255), transparent)',
  }

  return (
    <div className="newLayout">
      <SEO canonical={isWordPage && 'https://lemot.app/'} />
      <div className="newWrapper">
        <Header hasColorizedNav />
        {/* <section
          // style={heroStyles}
          class="hero is-large "
        >
          <div class="hero-body ">
            <Wrapper>
              <Section flex={9}>
                <h1 style={heroTitleStyles} class="title is-1 has-text-black">
                  {title}
                </h1>
                <p class="subtitle has-text-black is-4">{subtitle}</p>
                <Cta
                  isLink
                  link="https://www.assimil.com/es/e-methodes/1774-e-metodo-frances-9782700564921.html?affiliates=76603"
                />
              </Section>
              <Modal />
              <Section flex={4}>
                <Book /> */}
        {/*                 
                <BoxWrapper>
                  <BoxContainer>
                    <FrontDiv>
                      <video autoPlay muted playsinline loop>
                        <source src="/test.mp4" type="video/mp4" />
                      </video>
                    </FrontDiv>
                    <BackDiv></BackDiv>
                  </BoxContainer>
                </BoxWrapper> */}

        {/* <BookAnimation /> */}
        {/* </Section>
            </Wrapper>
          </div>
        </section> */}
        <section id="word" class="hero is-medium is-white">
          <div class="hero-body">
            <Section>
              <h2 class="title has-text-centered">{wordBoxSection.title}</h2>
              <WordBoxV2 node={mot} />
              <div style={wordBoxActionsStyles.wrapper}>
                <div style={wordBoxActionsStyles.callToAction}>
                  <Link to={url && `/${url}`}>
                    <Cta text={wordBoxSection.callToAction} />
                  </Link>
                  <a
                    style={wordBoxActionsStyles.socialLink}
                    href="https://twitter.com/Lemot_app"
                    className="is-hidden-desktop"
                  >
                    <div style={wordBoxActionsStyles.icon}>
                      <div>
                        <img
                          src={'/assets/images/x-logo-black.png'}
                          alt="x-twitter-social"
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div
                style={wordBoxActionsStyles.callToAction1}
                className="container has-text-centered"
              >
                <p className="has-text-weight-light">
                  {wordBoxSection.callToAction1}
                </p>
                <br />
              </div>
            </Section>
            <p className="is-hidden-desktop has-text-weight-light has-text-centered">
              {wordBoxSection.description}
            </p>
          </div>
          <div className="is-hidden-desktop is-flex is-align-items-center">
            <ThreeBoxesLinks />
          </div>
        </section>
        <FreeProductOffer
          // image={data.CommunityImage.childImageSharp.fluid}
          dataTarget="FreeProductModalBlog"
          modalId="FreeProductModalBlog"
          formSrc="https://e7b4fa4e.sibforms.com/serve/MUIFAEOGNMsrge_whqfqlpjhH6TjQ1DQj6x_L3bospB1mXr30i4_DDVFXpyaqkjql3dqYlIutRQccC5FtpGCy1ZGrDjuEAo0oqD1-Zlwc-X0_7NOKYwOZA-HgxyG9JXz8wscHm-0E4-mJC2lU3LDOHx8hb8CzO3SaA_UDnxdoetfzCiy4ZJWI6sGPxMDh7fOrLwO78uSDb6PGERv"
          description={`No te pierdes ninguna palabra, inscribete y recibe la palabra
                del dia todos los dias en tu correo.`}
          cta="¡Únete ahora!"
          ctaIsLink
        />
        <div style={sectionsContainerStyles}>
          {sections.map(section => {
            const {
              title,
              content,
              imageUrl,
              imageAlt,
              frontDivColor,
              variation,
            } = section
            return (
              <div data-aos="fade-up" data-aos-offset="500">
                <section class="section is-medium">
                  <Wrapper>
                    <ContentSection
                      title={title}
                      content={content}
                      imageUrl={imageUrl}
                      imageAlt={imageAlt}
                      frontDivColor={frontDivColor}
                      variation={variation}
                    />
                  </Wrapper>
                </section>
              </div>
            )
          })}
          <section class="section is-medium">
            <Section display={'flex'}>
              <ThreeBoxes />
            </Section>
          </section>
          <section class="hero is-large is-black">
            <div class="hero-body">
              <Section>
                <Faq data={FaqData} styles={faqStyles} />
              </Section>
            </div>
          </section>
          <section class="section is-medium has-text-centered">
            <Section>
              <p class="subtitle has-text-black is-size-4">
                {bottomSection.description}
              </p>
              <Cta
                isLink
                link="https://www.assimil.com/es/e-methodes/1774-e-metodo-frances-9782700564921.html?affiliates=76603"
              />
            </Section>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default New
