import React from 'react'
import styled from 'styled-components'

import Section from '../components/section'

const ContentSectionContainer = styled.div`
  display: flex;
  width: 100%;
  margin: auto;

  & > div:first-child {
    width: 100%;
    margin: auto;
    order: ${props => (props.variation ? 1 : 0)};
  }

  & > div:last-child {
    width: 100%;
    margin: auto;
    order: ${props => (props.variation ? 0 : 1)};
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & > div:first-child {
      order: 0;
    }
    & > div:last-child {
      order: 1;
    }
  }
`

const ContentSection = ({
  title,
  content,
  imageUrl,
  frontDivColor,
  variation,
  imageAlt,
}) => {
  return (
    <ContentSectionContainer variation={variation}>
      <div style={{flex: 6}}>
        <Section>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div class="test">
              <div
                style={{
                  display: 'flex',
                  height: 280,
                  borderRadius: 8,
                  border: '3px solid black',
                  background: frontDivColor,
                }}
                class="front-div"
              >
                <div
                  style={{
                    width: 100,
                    height: 100,
                    display: 'flex',
                    margin: 'auto',
                    alignContent: 'center',
                  }}
                >
                  <img
                    alt={imageAlt}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      margin: 'auto',
                      height: '100%',
                    }}
                    src={imageUrl}
                  />
                </div>
              </div>
              <div
                style={{
                  height: 280,
                  borderRadius: 8,
                  border: '3px solid black',
                  background: 'white',
                }}
                class="back-div"
              ></div>
            </div>
          </div>
        </Section>
      </div>
      <div style={{flex: 9}}>
        <Section>
          <div class="has-text-weight-medium">
            <h2 class="title is-2">{title}</h2>
            <div class="m-4 is-size-5">
              <p dangerouslySetInnerHTML={{__html: content}} />
            </div>
          </div>
        </Section>
      </div>
    </ContentSectionContainer>
  )
}

export default ContentSection
