import {useState, useEffect} from 'react'
import mots from '../../content/mots'

const useRandomWord = mot => {
  const filterArrayByMot = (array, motToFilter) => {
    return array.filter(item => item.mot !== motToFilter.mot)
  }

  const filteredWords = filterArrayByMot(mots, mot)

  const [toggle, setToggle] = useState(true)
  const [word, setWord] = useState()
  const [url, setUrl] = useState(null)

  const getRandomWord = () => {
    const randomIndex = Math.floor(Math.random() * filteredWords.length)
    const randomWord = filteredWords[randomIndex]
    setWord(randomWord)
  }

  useEffect(() => {
    if (toggle !== undefined && toggle === true) {
      getRandomWord()
    }
  }, [toggle && toggle === true])

  useEffect(() => {
    const wordUrl = word?.trad?.toLowerCase()
    setUrl(`${wordUrl}#word`)
  }, [word])

  return {url}
}

export default useRandomWord
