import React from 'react'
import styled from 'styled-components'

const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between; /* Adjust as needed for spacing */
  gap: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: auto;
  }
`

const BoxContainer = styled.div`
  position: relative;
  width: 280px;
  height: 130px;
  /* width: 100%;
  height: 100%; */
  /* flex: 1; */
`

const FrontDiv = styled.div`
  position: absolute;
  flex-direction: column;
  display: flex;
  border-radius: 8px;
  border: 3px solid black;
  width: 100%;
  height: 100%;
  background-color: white; /* Front div color */
  z-index: 2; /* Higher z-index to appear in front */
  transform: translateZ(50px); /* Adjust the depth of the front div */
  align-items: center;
  justify-content: center;
`

const BackDiv = styled.div`
  position: absolute;
  border-radius: 8px;
  border: 3px solid black;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  background-color: #e74c3c; /* Back div color */
  z-index: 1; /* Lower z-index to appear behind */
`

const CatchDiv = styled.div`
  font-size: 2rem;
`

const IconDiv = styled.div`
  font-size: 2rem;
`

const ThreeBoxes = () => {
  return (
    <BoxWrapper>
      <div data-aos="zoom-in">
        <BoxContainer>
          <FrontDiv>
            <CatchDiv>Estudie</CatchDiv> <IconDiv>🧑‍🏫</IconDiv>
          </FrontDiv>
          <BackDiv style={{background: '  #57d0ff'}}></BackDiv>
        </BoxContainer>
      </div>
      <div data-aos="zoom-in" data-aos-delay="300">
        <BoxContainer>
          <FrontDiv>
            <div class="is-size-3">Práctica</div>{' '}
            <div class="is-size-3">✍️</div>
          </FrontDiv>
          <BackDiv style={{background: '#F070FF'}}></BackDiv>
        </BoxContainer>
      </div>
      <div data-aos="zoom-in" data-aos-delay="600">
        <BoxContainer>
          <FrontDiv>
            <div class="is-size-3">Repite</div> <div class="is-size-3">🔁</div>
          </FrontDiv>
          <BackDiv style={{background: '#A2FF4A'}}></BackDiv>
        </BoxContainer>
      </div>
    </BoxWrapper>
  )
}

export default ThreeBoxes
