import React, {Children} from 'react'

import {socialTrigger} from '../../../helpers/voiceCallback.js'

import styled from 'styled-components'

const Button = styled.h3`
  border: 3px solid black;
  border-radius: 8px;
  width: fit-content;
  padding: 15px;
  box-shadow: rgb(0, 0, 0) 3px 4px 0px;
  line-break: auto;
  @media screen and (max-width: 767px) {
    line-break: auto;
    white-space: break-spaces;
  }
`

const SpeakerButton = ({id, sentenceToSpeak, parameters, children}) => {
  const handleClick = () => {
    // socialTrigger()
    window.responsiveVoice.speak(sentenceToSpeak, 'French Female', parameters)
  }

  return (
    <div
      id={id}
      role="button"
      style={{marginLeft: '0px', padding: '0px'}}
      className="button voiceButton title"
      onClick={handleClick}
    >
      <span className="tooltip is-hidden-touch is-size-5">Escuchar</span>
      {/* <div style={{fontSize: 25}} id="test" className="is-loading">
        {children} 🗣️
      </div> */}
      <Button className="is-size-5 has-text-weight-bold">
        {sentenceToSpeak} 🗣️
      </Button>
    </div>
  )
}

export default SpeakerButton
